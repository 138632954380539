.marketing__hero {
  position: relative;
  height: 100%;
  min-height: 980px;
  border-bottom: 8px solid #00325a;
  color: #00325a;
  overflow: hidden;
}

.marketing__hero_image {
  position: absolute;
  top: 0;
  right: -70px;
  width: 100%;
  height: 100%;
  background-position: right -10vw center;
  background-size: contain;
  background-repeat: no-repeat;
}

.marketing__hero h1 {
  margin-top: calc(var(--width-increment) * 3.75vw);
  line-height: calc(var(--width-increment) * 2.75vw);
  padding: 0;
  font-size: calc(var(--width-increment) * 2.5vw);
  font-weight: normal;
}

.marketing__hero_corner {
  position: absolute;
  bottom: 0;
  left: 0;
}

/**
 * Slim variation for most content pages.
 */
.marketing__hero_slim {
  padding: 165px 0;
  background: #00325a;
  color: #fff;
}

.marketing__hero_slim .marketing__hero_image {
  width: 1160px;
}

.marketing__heroContentContainer {
  padding-left: calc(175px * var(--width-increment));
  padding-right: 50%;
  padding-top: calc(140px * var(--height-increment));
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 50vw;
  max-height: 100vh;
  max-width: 213vh;
  margin: auto;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/**
 * Tiny variation
 */
.marketing__hero_tiny {
  padding: 100px 0;
  background: #00325a;
  color: #fff;
}

.marketing__hero_tiny h1 {
  margin-bottom: 0;
}

/**
 * Centered variation
 */
.marketing__hero.marketing__hero_centered h2 {
  display: block;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-height: 1100px) {
  .marketing__heroContentContainer {
    padding-left: calc(125px * var(--width-increment));
    max-width: 220vh;
  }
  .marketing__hero h1 {
    margin-top: 0;
    line-height: calc(var(--width-increment) * 2.5vw);
    font-size: calc(var(--width-increment) * 1.75vw);
  }
}

@media screen and (max-width: 1800px) {
  .marketing__hero_image {
    background-position: right -25vw top;
  }
}

@media screen and (max-width: 1281px) {
  .marketing__heroContentContainer {
    padding-left: calc(125px * var(--width-increment));
  }

  .marketing__hero h1 {
    font-size: 1.75rem;
  }

  .marketing__hero_corner {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 980px) {
  .marketing__hero {
    padding: var(--section-padding-small);
  }

  .marketing__hero h1 {
    max-width: 100%;
    margin: 0;
  }

  .marketing__hero h2 {
    font-size: var(--heading-2-size);
  }

  .marketing__hero_image {
    left: 0%;
    width: 100%;
    opacity: 0.25;
    transform: scale(1.5);
    background-image: none !important;
    background-color: #e8f5f7;
  }

  .marketing__hero_corner {
    display: none;
  }

  .marketing__heroContentContainer {
    padding: 2.5rem;
    text-align: center;
    height: 100%;
  }

  .marketing__heroContentContainer h1 {
    margin-bottom: 2.5rem;
    text-align: center;
    font-size: 6.5vw;
    line-height: 10vw;
  }
}
