.marketing__cta {
  position: relative;
  padding: calc(80px * var(--height-increment)) 0
    calc(110px * var(--height-increment));
  background: #00ab9d;
  color: #fff;
  text-align: center;
  border-top: 8px solid #00325a;
  border-bottom: 8px solid #00325a;
}

.marketing__cta_blue {
  background: var(--brand-secondary);
  border-top-color: var(--brand-primary);
  border-bottom-color: #fff;
}

.marketing__cta h2 {
  margin-bottom: 1.5rem !important;
  padding: 0;
  font-size: var(--heading-2-size);
  font-weight: normal;
  color: #fff !important;
}

.marketing__cta p {
  margin: 0rem 0 3rem !important;
  font-size: 22px;
  color: #fff !important;
}

.marketing_cta_tailicon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

@media screen and (max-width: 980px) {
  .marketing__cta p {
    font-size: 1rem;
    margin-bottom: 2rem !important;
  }

  .marketing_cta_tailicon {
    transform: translate(-50%, 50%) scale(0.75);
  }
}
