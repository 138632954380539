.marketing__articles {
  padding: calc(120px * var(--height-increment)) 0
    calc(60px * var(--height-increment));
  background: #f2f2f2;
  box-sizing: border-box;
}

.marketing__articles_title {
  margin-bottom: 4rem !important;
  color: var(--brand-secondary) !important;
  font-size: var(--heading-2-size) !important;
  font-weight: normal;
  text-align: center;
}

.marketing__articles_cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 3rem;
  max-width: calc(1645px * var(--width-increment));
  margin: 0 auto;
}

.marketing__articles_card {
  position: relative;
}

.marketing__articles_card_image {
  display: block;
  height: calc(370px * var(--width-increment));
  background: #f8f8f8;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.marketing__articles_card_date {
  margin: 2.5rem 0 0.75rem;
  color: var(--brand-secondary);
  font-size: 1.125rem;
  font-weight: 500;
}

.marketing__articles_card_title {
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-weight: normal;
  color: var(--brand-primary);
}

.marketing__articles_card_excerpt {
  margin: 0 0 1.5rem;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.25em;
  color: #707070;
  height: 160px;
  overflow: hidden;
}

.marketing__articles_card_link {
  cursor: pointer;
}

@media screen and (max-width: 980px) {
  .marketing__articles_cards {
    grid-template-columns: 1fr;
  }

  .marketing__articles_card_image {
    height: calc(100px * var(--width-increment));
  }

  .marketing__articles_card_date {
    margin: 1.5rem 0 0;
    font-size: calc(var(--font-size-base) * 1.25);
  }

  .marketing__articles_card_title {
    margin-bottom: 1rem;
    font-size: calc(var(--font-size-base) * 2);
  }

  .marketing__articles_card_excerpt {
    height: auto;
  }

  .marketing__articles_card > a {
    text-align: center;
  }
}
