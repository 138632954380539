.marketing__benefits {
  position: relative;
  padding: calc(140px * var(--height-increment)) 0
    calc(120px * var(--height-increment));
  background: rgb(0, 50, 90);
  color: #fff;
  text-align: center;
  background: linear-gradient(
    0deg,
    rgba(0, 33, 59, 1) 0%,
    rgba(0, 50, 90, 1) 100%
  );
  border-top: 8px solid var(--brand-primary);
  border-bottom: 8px solid var(--brand-primary);
  margin-top: -8px;
}

.marketing__video {
  position: relative;
  padding: calc(40px * var(--height-increment)) 0
    calc(50px * var(--height-increment));
  background: #00ab9d;
  color: #fff;
  text-align: center;
  border-top: 8px solid #00325a;
  border-bottom: 8px solid #00325a;
}

.marketing__video_blue {
  background: var(--brand-secondary);
  border-top-color: var(--brand-primary);
  border-bottom-color: #fff;
}

.marketing__benefits h2 {
  margin-top: 0;
  margin-bottom: 1.2rem !important;
  font-size: var(--heading-2-size);
  font-weight: normal;
  color: #fff !important;
}

.marketing__benefits_tabs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  font-size: 2rem;
  margin: 0 auto;
  max-width: calc(1225px * var(--width-increment));
}

.marketing__benefits_tab {
  padding: 1.75rem;
  border-bottom: 5px solid #fff;
  cursor: pointer;
}

.marketing__benefits_tab_active {
  color: var(--brand-primary);
  border-color: var(--brand-primary);
  border-width: 4px;
}

.marketing_benefits_icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin: 0 auto;
  max-width: calc(1630px * var(--width-increment));
}

.marketing_benefits_icon {
  padding: 6rem 0 0;
  max-width: calc(400px * var(--width-increment));
  font-size: 1.375rem;
  text-align: center;
}

.marketing_benefits_icon .marketing_benefits_icon_img {
  display: inline-block;
  background-color: var(--brand-primary);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  width: 85px;
  height: 85px;
  margin-bottom: 2.25rem;
  border-radius: 50%;
}

/* Light variant */

.marketing__benefits_light {
  background: linear-gradient(
    0deg,
    rgba(0, 131, 120, 1) 0%,
    rgba(0, 171, 157, 1) 100%
  );
  border-top: 8px solid var(--brand-secondary);
  border-bottom: 8px solid var(--brand-secondary);
}

.marketing__benefits_light .marketing__benefits_tab_active {
  color: var(--brand-secondary);
  border-color: var(--brand-secondary);
}

.marketing__benefits_light
  .marketing_benefits_icon
  .marketing_benefits_icon_img {
  background-color: var(--brand-secondary);
}

.marketing__benefits_tailicon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.marketing__benefits_buttonContainer {
  padding: calc(55px * var(--height-increment)) !important;
}

@media screen and (max-width: 980px) {
  .marketing_benefits_icons {
    grid-template-columns: 1fr 1fr;
  }

  .marketing_benefits_icon {
    padding: 1.5rem 0;
    font-size: 0.8rem;
  }

  .marketing_benefits_icon .marketing_benefits_icon_img {
    margin-bottom: 1rem;
  }

  .marketing__benefits_tabs {
    margin-bottom: 1.5rem;
  }

  .marketing__benefits_tab {
    padding: 1rem 0;
    font-size: 1rem;
  }

  .marketing__benefits_tailicon {
    transform: translate(-50%, 50%) scale(0.75);
  }
}
