.marketing__slider {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
}

.marketing__slider * {
  white-space: normal;
}

.marketing__slider_slide {
  display: inline-block;
  width: 100vw;
  transition: all ease-in-out 0.25s;
  height: 49.4vw;
  max-height: calc(100vh - 80px);
  overflow: hidden;
}

.marketing__slider_arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-appearance: none;
  cursor: pointer;
}

.marketing__slider_arrow:hover {
  opacity: 0.5;
}

.marketing__slider_arrow_left {
  left: 50px;
}

.marketing__slider_arrow_right {
  right: 50px;
}

.marketing__slider_markers {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
}

.marketing__slider_markers_marker {
  position: relative;
  display: inline-block;
  width: calc(var(--height-increment) * 25px);
  height: calc(var(--height-increment) * 25px);
  margin-right: 24px;
  background: #fff;
  border: 1px solid #707070;
  border-radius: 50%;
  cursor: pointer;
}

.marketing__slider_markers_marker_active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  background: var(--brand-primary);
  border-radius: 50%;
}

@media screen and (max-width: 1280px) {
  .marketing__slider_arrow_left {
    left: 25px;
  }
  .marketing__slider_arrow_right {
    right: 25px;
  }
}

@media screen and (max-width: 980px) {
  .marketing__slider_markers {
    bottom: 0.5rem;
  }

  .marketing__slider_arrow {
    display: none;
  }

  .marketing__slider_slide {
    height: calc(100vh - 80px);
  }
}
