.marketing__categories {
  padding: calc(140px * var(--height-increment)) 0
    calc(120px * var(--height-increment));
  box-sizing: border-box;
}

.marketing__categories h2 {
  margin-bottom: 1.75rem !important;
  padding: 0;
  font-size: var(--heading-2-size);
  font-weight: normal;
  color: var(--brand-primary);
  text-align: center;
}

.marketing__categories .marketing__categories_description {
  width: 90%;
  max-width: calc(700px * var(--width-increment));
  margin: 0 auto 2.5rem;
  font-size: 1.375rem;
  text-align: center;
  color: var(--brand-secondary);
}

.marketing__categories_prompt {
  margin: 0 auto 4.5rem;
  color: var(--brand-primary);
  font-size: 1.375rem;
  text-align: center;
}

.marketing__categories_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 3rem;
  max-width: calc(1645px * var(--width-increment));
  margin: 0 auto;
}

.marketing__categories_grid_item {
  position: relative;
  background: #f2f2f2;
  background-size: cover;
  background-repeat: no-repeat;
  height: min(calc(59vw / 3), 500px);
  min-height: 400px;
  margin: 0;
}

.marketing__categories_grid_item label {
  position: absolute;
  bottom: 12.5%;
  transform: translateY(50%);
  right: 3.25rem;
  z-index: 2;
}

.marketing__categories_grid_item_content {
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  padding: 1.25rem 1.75rem;
  background: linear-gradient(
    90deg,
    rgba(0, 171, 157, 1) 0%,
    rgba(0, 131, 120, 1) 100%
  );
  opacity: 0.95;
  color: #fff;
  clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
  height: 30%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.marketing__categories_grid_item_content strong {
  display: block;
  font-size: min(1.25vw, 1.5rem);
  line-height: 1em;
  font-weight: 500;
  width: 100%;
  flex: 1;
}

.marketing__categories_grid_item_content p {
  display: none;
  color: inherit !important;
  font-size: calc(0.25vw + 0.75rem);
}

.arrowCheckbox {
  display: none;
}

.arrowCheckbox:checked + label {
  top: 0;
  bottom: auto;
}

.arrowCheckbox:checked + label > svg {
  transform: rotate(180deg);
}

.arrowCheckbox:checked + label + .marketing__categories_grid_item_content {
  top: 0;
  height: 100%;
  align-items: flex-start;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.arrowCheckbox:checked + label + .marketing__categories_grid_item_content p {
  padding: 1.25rem 1.75rem 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: justify;
}

@media screen and (max-width: 1150px) {
  .marketing__categories_grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 980px) {
  .marketing__categories_grid_item_content strong {
    font-size: calc(2vw + 0.75rem);
  }

  .marketing__categories_grid_item_content p {
    font-size: 3.6vw;
  }

  .marketing__categories .marketing__categories_description,
  .marketing__categories_prompt {
    font-size: 1rem;
  }

  .marketing__categories_grid {
    grid-template-columns: 100%;
  }

  .marketing__categories_grid_item {
    min-height: calc(100vw - 2rem);
  }

  .marketing__categories_grid_item label {
    right: 1.75rem;
    transform: scale(0.75) translateY(50%);
  }
}
